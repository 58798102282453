var exports = {};
exports = extend;

function extend(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i],
        keys = Object.keys(source);

    for (var j = 0; j < keys.length; j++) {
      var name = keys[j];
      target[name] = source[name];
    }
  }

  return target;
}

export default exports;